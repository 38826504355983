import * as React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

export default function Thanks() {
  return (
    <Layout pageTitle="IMIK">
      <Helmet>
        <meta http-equiv="refresh" content="3; url='https://imik.netlify.app'" />
      </Helmet>
      <section className="reading">
        <article>
          <h1>Improvised Music in Kingston</h1>
          <p>Redirecting to https://imik.netlify.app/ ...</p>
        </article>
      </section>
    </Layout>
  )
}
